import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CidaasProvider } from './cidaas-login/cidaasProvider';
import { timeout } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

export interface ITokenEndpointBody {
  grant_type: string;
  client_id: string;
  code?: string;
  redirect_uri?: string;
  code_verifier?: string;
  refresh_token?: string;
}

export interface ITokenEndpointResponse {
  error?: string;
  error_description?: string;
  access_token?: string;
  refresh_token?: string;
  id_token?: string;
}

export interface IAuthObserver {
  observerId: number;
  onAuthChange(isLoggedIn: boolean);
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private static INIT_INTERVAL = 2000;
  private static MAX_INTERVAL = 6000;
  private static MAX_RETRIES = 3;
  private static REQ_TIMEOUT = 6000;

  private observers: IAuthObserver[] = [];

  constructor(private http: HttpClient) {}

  async oAuthCallback(body: ITokenEndpointBody): Promise<void> {
    console.log('oauth callback', body);
    const response: ITokenEndpointResponse = await this.tokenRequest(body);
    console.log(response);
    if (!response.access_token || !response.refresh_token) {
      throw new Error('Either Access_Token or Refresh_Token missing!');
    }

    const user = {
      access_token: response.access_token,
      refresh_token: response.refresh_token,
    };
    localStorage.setItem('user', JSON.stringify(user));
  }

  async tokenRequest(
    body: ITokenEndpointBody
  ): Promise<ITokenEndpointResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<ITokenEndpointResponse>(
        CidaasProvider.tokenEndpoint,
        body,
        httpOptions
      )
      .pipe(
        timeout(UserService.REQ_TIMEOUT),
        retryBackoff({
          initialInterval: UserService.INIT_INTERVAL,
          maxInterval: UserService.MAX_INTERVAL,
          maxRetries: UserService.MAX_RETRIES,
          shouldRetry: (error) => {
            return true;
          },
          backoffDelay: (iteration, initialInterval) =>
            Math.pow(1.5, iteration) * initialInterval,
        })
      )
      .toPromise();
  }

  async isLoggedIn(): Promise<boolean> {
    const helper = new JwtHelperService();
    const currentUser = JSON.parse(localStorage.getItem('user'));

    console.log('isLoggedIn:CurrentUser: ' + currentUser);
    if (currentUser) {
      console.log('isLoggedIn: TRUE');
      const isExpired = helper.isTokenExpired(currentUser.access_token);

      if (isExpired) {
        console.log(
          'THIS ACCESS_TOKEN IS EXPIRED in Login, try getting new one.'
        );
        this.notify(false);
        return false;
        
      } else {
        this.notify(true);
        return true;
      }
    } else {
      this.notify(false);
      return false;
    }
  }

  subscribe(observer: IAuthObserver) {
    this.unsubscribe(observer);
    const idx = this.observers.indexOf(observer);
    if (idx === -1) {
      this.observers.push(observer);
    }
  }

  unsubscribe(observer: IAuthObserver) {
    let idx = -1;
    for (const obs of this.observers) {
      if (obs.observerId === observer.observerId) {
        idx = this.observers.indexOf(obs);
      }
    }
    if (idx !== -1) {
      this.observers.splice(idx, 1);
    }
  }

  private notify(isLoggedIn) {
    this.observers.forEach((observer) => {
      observer.onAuthChange(isLoggedIn);
    });
  }

  public async revokeToken(token: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      token_type_hint: 'access_token',
      token
    };

    return this.http
      .post<ITokenEndpointResponse>(`${CidaasProvider.revokeUrl}?access_token_hint=${body.token}`, body, httpOptions)
      .pipe(
        timeout(UserService.REQ_TIMEOUT),
        retryBackoff({
          initialInterval: UserService.INIT_INTERVAL,
          maxInterval: UserService.MAX_INTERVAL,
          maxRetries: UserService.MAX_RETRIES,
          shouldRetry: error => {
            return false;
          },
          backoffDelay: (iteration, initialInterval) =>
            Math.pow(1.5, iteration) * initialInterval
        })
      )
      .toPromise();
  }
}
