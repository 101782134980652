<div class="background ">
    <div class="box">
        <h1>Search for Device</h1>
        <span *ngIf="error" class="error-message">{{
              error
            }}</span>
        <input (keyup.enter)="serialNumberRG ? getDeviceData() : undefined" autofocus [(ngModel)]="serialNumberRG" type="text" placeholder="Re.Guard Serial Number" autocomplete="on" />
        <input (keyup.enter)="serialNumberWS ? getDeviceData() : undefined" autofocus [(ngModel)]="serialNumberWS" type="text" placeholder="Watersensor Serial Number" autocomplete="on" />
        <select name="env" id="env" (change)="onEnvChange($event.target.value)">
            <option value="prod">Production</option>
            <option value="qa">QA</option>
        </select>
        <div class="login-wrapper">
            <button [disabled]="!serialNumberRG && !serialNumberWS" (click)="getDeviceData()" class="cta-button" type="submit">
                <label class="login-btn-label">View Device Data</label>
            </button>
            <span *ngIf="isLoading" class="spinner-container">
                <i class="fas fa-spinner spin"></i>
            </span>
        </div>
    </div>
</div>
