import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private router: Router,
    private userService: UserService
  ) {}

  serialNumberRG: any = "";
  serialNumberWS: any = "";
  error: any;
  isLoading: boolean;

  ngOnInit(): void {
    this.init();

    if (localStorage.getItem('data')) {
      this.router.navigate(['home']);
    }
  }

  async init() {
    const isLoggedIn = await this.userService.isLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['']);
    } else {
      this.dataService.setHeaders();
    }
  }

  onEnvChange(val: any) {
    localStorage.setItem('ENV', val);
  }

  getDeviceData() {
    this.isLoading = true;
    this.error = null;

    if (this.serialNumberRG !== "" && this.serialNumberWS !== "") {
      this.getBothData()
    } 
    else if (this.serialNumberRG !== "") {
      this.getReGuardData();
    }
    else if (this.serialNumberWS !== "") {
      this.getWaterSensorData();
    }
    
  }

  getReGuardData() {
    if (this.serialNumberRG.startsWith("LPRG")) {
      this.dataService.getReGuardStatus(this.serialNumberRG).subscribe(
        (data) => {
          localStorage.setItem('dataRG', JSON.stringify(data));
          localStorage.setItem('serialNumberRG', JSON.stringify(this.serialNumberRG));
          this.router.navigate(['home']);
        },
        (err) => {
          this.error = err.name + " | Status: " + err.status + " | Please try again or use another serial number!";
          this.serialNumberRG = "";
          this.isLoading = false;
        }
      );
    } else {
      this.error = "Re.Guard Serial Number has to start with 'LPRG'. Please try again!"
      this.serialNumberRG = "";
      this.isLoading = false;
    }
  }

  getWaterSensorData() {
    if (this.serialNumberWS.startsWith("LPWS")) {
      this.dataService.getWaterSensorStatus(this.serialNumberWS).subscribe(
        (data) => {
          localStorage.setItem('dataWS', JSON.stringify(data));
          localStorage.setItem('serialNumberWS', JSON.stringify(this.serialNumberWS));
          this.router.navigate(['home']);
        },
        (err) => {
          this.error = err.name + " | Status: " + err.status + " | Please try again or use another serial number!";
          this.serialNumberWS = "";
          this.isLoading = false;
        }
      );
    } else {
      this.error = "Watersensor Serial Number has to start with 'LPWS'. Please try again!"
      this.serialNumberWS = "";
      this.isLoading = false;
    }
  }

  getBothData() {
    if (this.serialNumberRG.startsWith("LPRG")) {
      this.dataService.getReGuardStatus(this.serialNumberRG).subscribe(
        (data) => {
          localStorage.setItem('dataRG', JSON.stringify(data));
          localStorage.setItem('serialNumberRG', JSON.stringify(this.serialNumberRG));
          this.getWaterSensorData()
        },
        (err) => {
          this.error = err.name + " | Status: " + err.status + " | Please try again or use another serial number!";
          this.serialNumberRG = "";
          this.isLoading = false;
        }
      );
    } else {
      this.error = "Re.Guard Serial Number has to start with 'LPRG'. Please try again!"
      this.serialNumberRG = "";
      this.isLoading = false;
    }
  }

}
