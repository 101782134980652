import { Injectable } from '@angular/core';
import { TimestreamQueryClient, QueryCommand, QueryCommandOutput } from "@aws-sdk/client-timestream-query";
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class TimestreamService {
    private timestreamQueryClient: TimestreamQueryClient;
  
    constructor() {
        this.timestreamQueryClient = new TimestreamQueryClient({
            region: 'eu-central-1', // frankfurt
            credentials: {
                accessKeyId: environment.awsAccessKeyId,
                secretAccessKey: environment.awsSecretAccessKey
            }
        });
    }

    async executeQuery(query: string): Promise<QueryCommandOutput> {
        const command = new QueryCommand({
            QueryString: query
        })

        return this.timestreamQueryClient.send(command);
    }
  }

