<div class="content">
    <p class="changeDevice" (click)="logout()">Change Device</p>
    <h1 class="pl-20">Device Information</h1>
    <div class="flex mt-10 pl-20 mobile-column">
        <!-- reguard -->
        <div class="flex" *ngIf="reguardData !=null">
            <img style="width: 64px; height: 65px;" src="assets/images/reguard_icon.svg">
            <div class="flex column ml-20 w-120 ">
                <span class="mini">Last Status Update</span>
                <span [ngClass]="{'red': !isOnline}">{{timeSince(lastUpdate)}} <i *ngIf="!isOnline" class="fas fa-exclamation-triangle"></i></span>
            </div>
        </div>
        <div class="flex" *ngIf="reguardData !=null">
            <div class="online-indicator">
                <img [src]="this.isOnline ? 'assets/images/online.svg' : 'assets/images/offline.svg'">
            </div>
            <span class="self-center">{{this.isOnline ? 'ONLINE': 'OFFLINE'}}</span>
        </div>
        <div class="flex" *ngIf="reguardData !=null">
            <div class="settings">
                <legend>Water volume unit</legend>
                <input type="radio" id="liters" name="unitChoice" value="1" [checked]="volumeUnit === 1" (change)="volumeUnit=1"/>
                <label for="liters">Liters</label>
                <br>
                <input type="radio" id="cumbicmeters" name="unitChoice" value="2" [checked]="volumeUnit === 2" (change)="volumeUnit=2"/>
                <label for="cumbicmeters">Cubic Meters</label>
            </div>
        </div>

        <!-- watersensor -->
        <div class="flex" *ngIf="watersensorData !=null" [ngClass]="{'devinfo_margin': reguardData !=null}">
            <img style="width: 74px; height: 75px;" src="assets/images/watersensor_icon.svg">
            <div class="flex column ml-20 w-120 ">
                <span class="mini">Last Status Update</span>
                <span [ngClass]="{'red': !isWatersensorOnline}">{{timeSince(lastWatersensorUpdate)}} <i *ngIf="!isWatersensorOnline" class="fas fa-exclamation-triangle"></i></span>
            </div>
        </div>
        <div class="flex" *ngIf="watersensorData !=null">
            <div class="online-indicator">
                <img [src]="this.isWatersensorOnline ? 'assets/images/online.svg' : 'assets/images/offline.svg'">
            </div>
            <span class="self-center">{{this.isWatersensorOnline ? 'ONLINE': 'OFFLINE'}}</span>
        </div>

        <!-- refresh button -->
        <div class="flex">
            <div class="refresh self-center" (click)="refreshData()">Reload Data</div>
            <span *ngIf="isLoading" class="spinner-container">
                <i class="fas fa-spinner spin"></i>
            </span>
        </div>


    </div>

    <!-- reguard -->
    <div class="selects">  
        <!-- status -->
        <details *ngIf="reguardData !=null" open>
            <summary class="paired-summary" [ngClass]="{ 'no-data': !reguardData }"> RE.GUARD Status
            </summary>
            <div class="padding-15">
                <div class="row">
                    <span class="key">Serial Number</span>
                    <span class="value">{{ reguardData.serialNumber || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Valve State</span>
                    <span class="value">{{ reguardData.reportedSettings.status.valveState || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Status</span>
                    <span class="value">{{ reguardData.reportedSettings.status.opModeNormal || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Mode</span>
                    <span class="value">{{ reguardData.reportedSettings.status.opModeSpecial || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Battery Supply</span>
                    <span class="value">{{ reguardData.reportedSettings.status.onBattery.toString() || '---'  }}</span>
                </div>
                <div *ngIf="reguardData.reportedSettings.status.onBattery" class="row">
                    <span class="key">Battery State of Charge</span>
                    <span class="value">{{ reguardData.reportedSettings.status.batterySoc || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Wifi Signal Strength</span>
                    <span class="value">{{  reguardData.wifiSignalStrength + ' dBm' || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Last Seen Date</span>
                    <span class="value">{{  getDate(reguardData.lastSeenDate, false) || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Last Seen Leakage State of Device</span>
                    <span class="value">{{  reguardData.reportedSettings.status.leakageErrorCode || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Firmware Version</span>
                    <span class="value">{{  reguardData.firmwareVersion || '---'   }}</span>
                </div>
            </div>
        </details>
        <!-- <div class="selects">
            <details *ngIf="reguardData !=null" open>
                <summary class="paired-summary" [ngClass]="{ 'no-data': !reguardData }"> RE.GUARD Values
                </summary>
                <div class="padding-15">
                    <div class="row">
                        <span class="key">Volume</span>
                        <span class="value">{{ reguardData.volume || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Pressure</span>
                        <span class="value">{{ reguardData.pressure || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Flow</span>
                        <span class="value">{{ reguardData.flow || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Temperature</span>
                        <span class="value">{{ reguardData.temperature || '---'  }}</span>
                    </div>
                </div>
            </details>
        </div> -->
        <div class="selects">
            <!-- drop leakage -->
            <details *ngIf="reguardData !=null">
                <summary class="paired-summary" [ngClass]="{ 'no-data': !reguardData }"> Drop Leakage  <span>{{ reguardData.reportedSettings.microLeakage.isEnabled ? '(Enabled)' : '(Disabled)'}}</span>
                </summary>
                <div class="padding-15">
                        <div class="row">
                            <span class="key">Start Time</span>
                            <span class="value">{{ reguardData.reportedSettings.microLeakage.schedule.startTime || '---'  }}</span>
                        </div>
                        <div class="row">
                            <span class="key">Weekday</span>
                            <span class="value">{{ getDaysOfWeek(reguardData.reportedSettings.microLeakage.schedule.daysOfWeek) || '---'  }}</span>
                        </div>
                        <div class="row">
                            <span class="key">Action</span>
                            <span class="value">{{ reguardData.reportedSettings.microLeakage.reaction || '---'  }}</span>
                        </div>
                    </div>
            </details>
        </div>
        <div class="selects">
            <!-- garden irrigation -->
            <details *ngIf="reguardData !=null">
                <summary class="paired-summary" [ngClass]="{ 'no-data': !reguardData }"> Garden Irrigation  <span>{{ reguardData.reportedSettings.temporaryDeactivationSchedulesEnabled ? '(Enabled)' : '(Disabled)'}}</span>
                </summary>
                <div class="padding-15">
                    <div class="row"><h1>Schedule 1  <span>{{ reguardData.reportedSettings.temporaryDeactivationSchedule1.isEnabled ? '(Enabled)' : '(Disabled)'}}</span></h1></div>
                    <div class="row">
                        <span class="key">Start Time</span>
                        <span class="value">{{ reguardData.reportedSettings.temporaryDeactivationSchedule1.startTime || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Weekday</span>
                        <span class="value">{{ getDaysOfWeek(reguardData.reportedSettings.temporaryDeactivationSchedule1.daysOfWeek) || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Duration</span>
                        <span class="value">{{ reguardData.reportedSettings.temporaryDeactivationSchedule1.duration || '---'  }} minutes</span>
                    </div>
                    <div class="row"><h1>Schedule 2  <span>{{ reguardData.reportedSettings.temporaryDeactivationSchedule2.isEnabled ? '(Enabled)' : '(Disabled)'}}</span></h1></div>
                    <div class="row">
                        <span class="key">Start Time</span>
                        <span class="value">{{ reguardData.reportedSettings.temporaryDeactivationSchedule2.startTime || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Weekday</span>
                        <span class="value">{{ getDaysOfWeek(reguardData.reportedSettings.temporaryDeactivationSchedule2.daysOfWeek) || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Duration</span>
                        <span class="value">{{ reguardData.reportedSettings.temporaryDeactivationSchedule2.duration || '---'  }} minutes</span>
                    </div>
                    <div class="row"><h1>Schedule 3  <span>{{ reguardData.reportedSettings.temporaryDeactivationSchedule3.isEnabled ? '(Enabled)' : '(Disabled)'}}</span></h1></div>
                    <div class="row">
                        <span class="key">Start Time</span>
                        <span class="value">{{ reguardData.reportedSettings.temporaryDeactivationSchedule3.startTime || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Weekday</span>
                        <span class="value">{{ getDaysOfWeek(reguardData.reportedSettings.temporaryDeactivationSchedule3.daysOfWeek) || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Duration</span>
                        <span class="value">{{ reguardData.reportedSettings.temporaryDeactivationSchedule3.duration || '---'  }} minutes</span>
                    </div>
                </div>   
            </details>
        </div>
        <div class="selects">
            <!-- mode -->
            <details *ngIf="reguardData !=null">
                <summary class="paired-summary" [ngClass]="{ 'no-data': !reguardData }"> Operation Mode
                </summary>
                <div class="padding-15">
                    <div class="row">
                        <h1>Automatic Away Mode  <span>{{ reguardData.reportedSettings.opMode.autoAbsent.isEnabled ? '(Enabled)' : '(Disabled)'}}</span></h1>
                    </div>
                    <div class="row">
                        <span class="key">Hours without water withdrawal</span>
                        <span class="value">{{ reguardData.reportedSettings.opMode.autoAbsent.time || '---'  }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Action</span>
                        <span class="value">{{ reguardData.reportedSettings.opMode.autoAbsent.action || '---'  }}</span>
                    </div>
                    <div class="row"><h1>Temporary Deactivation  <span>{{ reguardData.reportedSettings.status.opModeSpecial=='TEMPORARY' ? '(Enabled)' : '(Disabled)'}}</span></h1></div>
                    <div class="row">
                        <span class="key">Deactivation Period</span>
                        <span class="value">{{ reguardData.reportedSettings.opMode.temporaryDeactivationTime || '---'  }} hours</span>
                    </div>
                </div>
            </details>
        </div>
        <div class="selects">
            <!-- limits -->
            <details *ngIf="reguardData !=null">
                <summary class="paired-summary" [ngClass]="{ 'no-data': !reguardData }"> Limits
                </summary>
                <div class="padding-15">
                    <div class="row"><h1>Home</h1></div>
                    <div class="row">
                        <span class="key">Maximum withdrawal time</span>
                        <span class="value">{{ reguardData.reportedSettings.macroLeakage.present.duration || '---'  }} minutes</span>
                    </div>
                    <div class="row">
                        <span class="key">Maximum withdrawal volume</span>
                        <span class="value">{{ volumeUnit===1 ? reguardData.reportedSettings.macroLeakage.present.volume + " l" : reguardData.reportedSettings.macroLeakage.present.volume/1000 + " m³" }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Maximum flow rate</span>
                        <span class="value">{{ volumeUnit===1 ? reguardData.reportedSettings.macroLeakage.present.flowRate + " l/h" : reguardData.reportedSettings.macroLeakage.present.flowRate/1000 + " m³/h" }}</span>
                    </div>
                    <div class="row"><h1>Away</h1></div>
                    <div class="row">
                        <span class="key">Maximum withdrawal time</span>
                        <span class="value">{{ reguardData.reportedSettings.macroLeakage.absent.duration || '---'  }} minutes</span>
                    </div>
                    <div class="row">
                        <span class="key">Maximum withdrawal volume</span>
                        <span class="value">{{ volumeUnit===1 ? reguardData.reportedSettings.macroLeakage.absent.volume + " l" : reguardData.reportedSettings.macroLeakage.absent.volume/1000 + " m³" }}</span>
                    </div>
                    <div class="row">
                        <span class="key">Maximum flow rate</span>
                        <span class="value">{{ volumeUnit===1 ? reguardData.reportedSettings.macroLeakage.absent.flowRate + " l/h" : reguardData.reportedSettings.macroLeakage.absent.flowRate/1000 + " m³/h" }}</span>
                    </div>
                    <div class="row"><h1>Water Temperature  <span>{{ reguardData.reportedSettings.temperatureLimit.isEnabled ? '(Enabled)' : '(Disabled)'}}</span></h1></div>
                    <div class="row">
                        <span class="key">Maximum water temperature</span>
                        <span class="value">{{ reguardData.reportedSettings.temperatureLimit.max || '---'  }}°C</span>
                    </div>
                    <div class="row"><h1>Stagnating Water  <span>{{ reguardData.reportedSettings.stagnatedWaterLimit.isEnabled ? '(Enabled)' : '(Disabled)'}}</span></h1></div>
                    <div class="row">
                        <span class="key">Stagnating water duration</span>
                        <span class="value">{{ reguardData.reportedSettings.stagnatedWaterLimit.duration || '---'  }} hours</span>
                    </div>
                </div>
            </details>
        </div>

        <!-- watersensor -->
        <details *ngIf="watersensorData != null" open>
            <summary class="paired-summary" [ngClass]="{ 'no-data': !watersensorData }"> Watersensor Status
            </summary>
            <div class="padding-15">
                <div class="row">
                    <span class="key">Serial Number</span>
                    <span class="value">{{ watersensorData.serialNumber || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Wifi Signal Strength</span>
                    <span class="value">{{  watersensorData.wifiSignalStrength + ' dBm' || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Last Seen Date</span>
                    <span class="value">{{  getDate(watersensorData.lastSeenDate, false) || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Firmware Version</span>
                    <span class="value">{{  watersensorData.firmwareVersion || '---'   }}</span>
                </div>
            </div>
        </details>


<!--         <br><br><hr><br> -->
        <!-- AWS Timestream -->
<!--         <input type="number" step="1" min="1" placeholder="Lookback in Days" class="custom-input" (change)="onInputChange($event.target.value)"> 

        <button (click)="executeTimestreamQuery()" class="cta-button" type="submit">
            <label class="login-btn-label">Run Query</label>
        </button>

        <span *ngIf="isLoading" class="spinner-container">
            <i class="fas fa-spinner spin"></i>
        </span>

        <br>
        <span *ngIf="error" class="error-message">{{ error }}</span>
        
        <table class="styled-table" *ngIf="timestreamResult.Rows.length != 0">
            <thead>
              <tr>
                <th>Thing Name</th>
                <th>Thing Type</th>
                <th>Measure Name</th>
                <th>Time</th>
                <th>Measure Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ts of timestreamResult.Rows">
                <td>{{ ts.Data[0].ScalarValue }}</td>
                <td>{{ ts.Data[1].ScalarValue }}</td>
                <td>{{ ts.Data[2].ScalarValue }}</td>
                <td>{{ ts.Data[3].ScalarValue }}</td>
                <td>{{ ts.Data[4].ScalarValue }}</td>
              </tr>
            </tbody>
          </table> -->
    </div>
</div>
