import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { UserService } from '../user.service';
import { TimestreamService } from '../timestream.service';

enum Device {
  Reguard = 0,
  Watersensor = 1,
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private dataService: DataService,
    private userService: UserService,
    private timestreamService: TimestreamService
  ) {}

  reguardData: any;
  watersensorData: any;
  lastUpdate: any;
  lastWatersensorUpdate: any;
  updateTimeInSeconds = 20;
  serialNumberRG: any;
  serialNumberWS: any;
  timestreamResult = {Rows: []};
  lookback: number;
  error: any;

  isOnline = false;
  isWatersensorOnline = false;
  isLoading = false;
  volumeUnit = 1;

  timer: any;

  ngOnInit(): void {
    if (localStorage.getItem('serialNumberRG')) {
      this.serialNumberRG = JSON.parse(localStorage.getItem('serialNumberRG'));
    }
    if (localStorage.getItem('serialNumberWS')) {
      this.serialNumberWS = JSON.parse(localStorage.getItem('serialNumberWS'));
    }
    this.initialize();

    this.timer = setInterval(() => {
      this.initialize();
    }, this.updateTimeInSeconds * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  public getDate(d, isTimestamp?: boolean): string {
    const date = isTimestamp ? new Date(Number(d)) : new Date(d);

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  }

  public getDaysOfWeek(arr: number[]): string[] {
    const daysOfWeek = [" Mon", " Tue", " Wed", " Thu", " Fri", " Sat", " Sun"];
    return arr.map((i) => daysOfWeek[i-1]);
  }

  async initAuth() {
    const isLoggedIn = await this.userService.isLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['']);
    }
  }

  public initialize() {
    this.initAuth();
    if (!localStorage.getItem('dataRG') && !localStorage.getItem('dataWS')) {
      this.router.navigateByUrl('');
    } else {
      this.reguardData = JSON.parse(localStorage.getItem('dataRG'));
      this.watersensorData = JSON.parse(localStorage.getItem('dataWS'));
      this.calculateLastStatusUpdate();

      this.getState(Device.Reguard); //0
      this.getState(Device.Watersensor); //1
    }
  }

  private calculateLastStatusUpdate() {
    if (this.reguardData != null) {
      this.lastUpdate = new Date(this.reguardData.lastSeenDate);
    }
    if (this.watersensorData != null) {
      this.lastWatersensorUpdate = new Date(this.watersensorData.lastSeenDate);
    }
  }

  public timeSince(date: any) {
    const now = new Date();

    const seconds = Math.floor(
      (now.getTime() - new Date(date).getTime()) / 1000
    );

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + ' years ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ' days ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ' hours ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  public getState(d) {
    const now = new Date();
    const secondsSinceUpdate = Math.floor(
      (now.getTime() - new Date(d == 0 ? this.lastUpdate  : this.lastWatersensorUpdate).getTime()) / 1000
    );

    d == 0 ? this.isOnline = false : this.isWatersensorOnline = false;

    console.log("seconds since update: " + secondsSinceUpdate);
    // rg
    if (d == 0 && this.lastUpdate) {
      if (secondsSinceUpdate < 3600) {  //1h
        this.isOnline = true;
      }
    }
    // ws
    else if (d == 1 && this.lastWatersensorUpdate) {
      if (secondsSinceUpdate < 90000) {  //25h
        this.isWatersensorOnline = true;
      }
    }
  }

  refreshData() {
    this.isLoading = true;
    if (localStorage.getItem('serialNumberRG')) {
      this.dataService.getReGuardStatus(this.serialNumberRG).subscribe(
        (data) => {
          localStorage.setItem('dataRG', JSON.stringify(data));
          (console.log("got rg data"));
        }
      );
    }
    if (localStorage.getItem('serialNumberWS')) {
      this.dataService.getWaterSensorStatus(this.serialNumberWS).subscribe(
        (data) => {
          localStorage.setItem('dataWS', JSON.stringify(data));
          (console.log("got ws data"));
        }
      );
    }
    console.log("initialize");
    this.initialize();
    this.isLoading = false;
    }

/*   async executeTimestreamQuery() {
    const query = `SELECT * FROM "rehau-reguard-timestream-prod"."event" WHERE time between ago(${this.lookback}d) and now() and thingName='${this.serialNumberRG}' ORDER BY time DESC`
    this.isLoading = true;
    this.error = null;

    if (!this.lookback || this.lookback <= 0) {
      this.error = "Please enter a lookback!";
      this.isLoading = false;
    } else {
      try {
        this.timestreamResult = await this.timestreamService.executeQuery(query);
        console.log(this.timestreamResult.Rows);
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.error = err.name + " | Message: " + err.message + " | Please try again!";
        this.isLoading = false;
      }
    }
  } */

  onInputChange(val: any) {
    this.lookback = val;
  }

  logout() {
    this.router.navigateByUrl('serial');
    const user = JSON.parse(localStorage.getItem('user'));
    this.dataService.isDeviceSet.next(false);
    localStorage.clear();
    localStorage.setItem('user', JSON.stringify(user));
  }

  isObject(val: any): boolean {
    return typeof val === 'object';
  }
}
