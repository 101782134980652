import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  headers: any;
  baseURL: any;
  isDeviceSet = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.setHeaders();
  }

  setHeaders() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + user.access_token);
    }
    const serialNumber = localStorage.getItem('serialNumber');
    if (!!serialNumber) {
      this.isDeviceSet.next(true);
    }
  }

  getReGuardStatus(serialNumber: any) {
    return this.http.get(
      this.getBaseUrl() + '/reguards/' + serialNumber,
      { headers: this.headers }
    );
  }

  getWaterSensorStatus(serialNumber: any) {
    return this.http.get(
      this.getBaseUrl() + '/water-sensors/' + serialNumber,
      { headers: this.headers }
    );
  }
  // test serial number: LPRG22230c1
  // test water sensor serial number: LPWS2220781E5

  private getBaseUrl() {
    let env = localStorage.getItem('ENV');

    if (!env) {
      localStorage.setItem('ENV', 'prod');
      env = 'prod';
    }

    if (env === 'qa') {
      return (this.baseURL = 'https://api.qa.reguard2.aws.rehau.cloud/v1/admin');
    } else if (env === 'prod') {
      return (this.baseURL = 'https://api.reguard2.aws.rehau.cloud/v1/admin');
    }
  }
}
